import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MetrikaModule } from 'ng-yandex-metrika';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxGoogleAnalyticsModule.forRoot('G-SDK5JE4XVY'),
    MetrikaModule.forRoot({
      id: '75554386',
      webvisor: true,
      accurateTrackBounce: true,
      clickmap: true
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
